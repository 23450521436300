import { get, post } from '@/utils/request'

const videoApi = {
  userContentDataApi: '/api/content/userdata',
  contentAccessCodeApi: '/api/content/userdata',
  ossServerApi: '/api/file/oss/serverinfo',
  ossServerApi1: '/api/content/oss/serverinfo',
  siteNoticeApi: '/api/content/site/notice',
  userRcmdApi: '/api/content/rcmd/user',
  dislikeVideoApi: '/api/content/rcmd/dislike'
}

/** *******************************************************************************************************************/
// 获取用户内容统计
export function getUserContentData(userId) {
  return get(videoApi.userContentDataApi + '?userId=' + userId)
}

// 提交内容访问码
export function submitAccessCode(jsonData) {
  return post(videoApi.contentAccessCodeApi, jsonData)
}

export function getServerInfo(channelId) {
  return post(videoApi.ossServerApi + '?channelId=' + channelId)
}

// 获取站点公告
export function getSiteNotice() {
  return get(videoApi.siteNoticeApi)
}

// 获取用户的推荐模式
export function getUserRcmd() {
  return get(videoApi.userRcmdApi)
}

// 设置用户的推荐模式
export function setUserRcmd(jsonData) {
  return post(videoApi.userRcmdApi, jsonData)
}

// 提交用户不喜欢的视频
export function dislikeVideo(jsonData) {
  return post(videoApi.dislikeVideoApi, jsonData)
}
